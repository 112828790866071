<template>
    <div class="business-privacyPolicy" style="position: relative;">
        <BlueBackground/>
        <div class="row"  style="background: white;">
            <div class="col-12 col-md-4 main-title">
                {{ getTitle(manager) }}
            </div>
            <div class="col-12 col-md-8 mt-2 mt-md-0" style="min-height: 400px;">
                <div class="accordion" role="tablist">
                    <div class="borderBottom" v-for="(item, index) of businessPrivacyPolicies" :key="item.id">
                        <b-button class="button-header-legal-notice p-0 py-1" block v-b-toggle :href="'#privacyPolicy_'+item.id" @click.prevent>{{item.title}} <span class="when-open">-</span><span class="when-closed">+</span></b-button>
                        <b-collapse :id="'privacyPolicy_'+item.id" :visible="index === 0 ? true:false" accordion="my-accordion" role="tabpanel">
                            <div class="html-display" v-html="item.content"/>
                            <b-button class="button-end-legal-notice p-0 py-1" v-b-toggle :href="'#privacyPolicy_'+item.id" @click.prevent>
                                Zapri
                                <img style="height: 17px; width:17px; margin-left: 5px;" :src="require('@/assets/images/icons/Minus.svg')">
                            </b-button>
                        </b-collapse>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { BCollapse, BButton, VBToggle} from 'bootstrap-vue'
    import Vue from 'vue'
    import BlueBackground from '@/views/Components/BlueBackground'

    export default {
        components:{
            BlueBackground,
            BCollapse, 
            BButton
        },
        data() {
            return {
                businessPrivacyPolicies: [],
                manager: this.$route.params.upravnik ?? 'basic'
            }
        },
        methods:{
            async loadData() {
                try {
                    const response = await this.$http.get(`/api/user/v1/business_privacy_policies/${this.manager}`)
                    this.businessPrivacyPolicies = response.data
                } catch (err) {
                    this.$printError('Prišlo je do napake pri nalaganju podatkov')
                }
            },
            getTitle(manager) {
                if (manager === 'basic') {
                    return 'Pogoji varovanja osebnih podatkov'
                } else if (manager === 'habit') {
                    return 'Pogoji varovanja osebnih podatkov Habit d.o.o.'
                } else if (manager === 'stanInvest') {
                    return 'Pogoji varovanja osebnih podatkov Staninvest d.o.o.'
                }
            }
        },
        created() {
            Vue.directive('b-toggle', VBToggle)
        },
        watch:{
            '$route.params.upravnik': {
                handler(newValue) {
                    this.manager = newValue ?? 'basic'
                    this.loadData()
                },
                deep: true
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>
.button-end-legal-notice, .button-end-legal-notice:hover, .button-end-legal-notice:active, .button-end-legal-notice:focus{
    border: none !important;
    background: white !important;
    color: black !important;
    text-align: left;
    font-weight: bold;
    font-size: 13px;
    box-shadow: none !important;
}
.button-header-legal-notice, .button-header-legal-notice:hover, .button-header-legal-notice:active, .button-header-legal-notice:focus{
    border: none !important;
    background: white !important;
    color: black !important;
    text-align: left;
    font-weight: bold;
    font-size: 1.3rem;
    box-shadow: none !important;
}

.main-title{
    font-size: 3rem;
    font-weight: 900;
}

.title-content{
    font-weight: bold;
    font-size: 1.3rem;
}

.borderBottom{
    border-bottom: 1px solid black;
}
</style>

<style>
.business-privacyPolicy .collapsed > .when-open,
.business-privacyPolicy .not-collapsed > .when-closed {
  display: none;
}
</style>